import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { valueToMoney } from '@app/shared';
import { AmountComponent, ButtonSpinnerComponent, ContentToolbarComponent } from '@givve/ui-kit/components';
import { LetDirective } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';
import { NgxCurrencyDirective } from 'ngx-currency';
import { LunchTaxRules } from './models/lunch-tax-rules';
import { LunchTaxRulesStore } from './stores/lunch-tax-rules.store';

@Component({
  selector: 'app-lunch-tax-rules',
  standalone: true,
  imports: [
    CommonModule,
    ContentToolbarComponent,
    TranslateModule,
    MatCardModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatInputModule,
    FormsModule,
    ButtonSpinnerComponent,
    MatButtonModule,
    MatDatepickerModule,
    LetDirective,
    MatTableModule,
    NgxCurrencyDirective,
    AmountComponent,
  ],
  templateUrl: './lunch-tax-rules.component.html',
  styleUrl: './lunch-tax-rules.component.scss',
})
export class LunchTaxRulesComponent {
  private fb = inject(FormBuilder);
  private lunchTaxRulesStore = inject(LunchTaxRulesStore);

  vm$ = this.lunchTaxRulesStore.vm$;

  displayedColumnsResult: string[] = ['credit_amount', 'flat_taxed_amount', 'tax_free_amount'];
  displayedColumnsDebugging: string[] = [
    'tax_rule_class',
    'effective_max_credit_amount',
    'effective_flat_tax_limit',
    'effective_max_direct_tax_free_amount',
    'effective_max_transferred_tax_free_amount',
  ];

  form = this.fb.nonNullable.group({
    date: ['', [Validators.required]],
    transaction_amount: [, [Validators.required]],
    max_credit_amount: [, [Validators.required]],
  });

  submit() {
    const lunchTaxRules = {
      date: this.form.value.date,
      transaction_amount: valueToMoney(this.form.value.transaction_amount!),
      max_credit_amount: valueToMoney(this.form.value.max_credit_amount!),
      country_code: 'DE',
    } as LunchTaxRules;
    this.lunchTaxRulesStore.calculateLunchTax(lunchTaxRules);
  }
}

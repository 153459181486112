import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthFacade } from '@app/auth';
import { environment } from '@app/env';
import { Releasenote } from '@app/features/releasenotes/models/releasenote';
import { ReleasenotesService } from '@app/shared/services/releasenotes.service';
import { ApiResponseLinks, StatusType } from '@givve/ui-kit/models';
import { NotificationService } from '@givve/ui-kit/services';
import { Errors } from '@givve/ui-kit/utils';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { filter, of, switchMap, tap, timer } from 'rxjs';
import { ReleasenotesDialogComponent } from '../releasenotes-dialog.component';

export interface ReleasenotesDialogState {
  statusType: StatusType;
  releasenotes: Releasenote[];
  links: Partial<ApiResponseLinks>;
  dialogopened: boolean;
}

export const DEFAULT_STATE: ReleasenotesDialogState = {
  statusType: 'INIT',
  releasenotes: [],
  links: {},
  dialogopened: false,
};

const POLLING_INTERVALL = 300000; //5 minutes

@Injectable({ providedIn: 'root' })
export class ReleasenotesDialogStore extends ComponentStore<ReleasenotesDialogState> {
  private releasenotesService = inject(ReleasenotesService);
  private notification = inject(NotificationService);
  private translate = inject(TranslateService);
  private dialog = inject(MatDialog);
  private authFacade = inject(AuthFacade);

  readonly vm$ = this.select((state) => state);

  constructor() {
    super(DEFAULT_STATE);
  }

  readonly loadData = this.effect<void>(() =>
    this.authFacade.isLoggedIn$.pipe(
      /** Check for new relasenotes every n milliseconds. n is defined in  POLLING_INTERVALL.*/
      switchMap(
        (isLoggedIn) =>
          isLoggedIn && environment.changelogPolling
            ? timer(0, POLLING_INTERVALL).pipe(
                /** Skip check if releasenote dialog is already opened */
                filter(() => !this.state().dialogopened),
                tap(() => this.patchState({ statusType: 'LOADING' })),
                switchMap(() =>
                  this.releasenotesService.getReleasenotesGreaterThan(localStorage.getItem('last_seen')).pipe(
                    tapResponse(
                      (response) => {
                        const hasData = response.data?.length > 0;
                        this.patchState({
                          statusType: 'DATA',
                          releasenotes: response.data,
                          links: response.links,
                          dialogopened: hasData ? true : false,
                        });
                        if (hasData) {
                          this.openReleasenotesDialog(response.data);
                        }
                      },
                      (error) => {
                        this.patchState({ statusType: 'ERROR' });
                        this.notification.open({
                          message: Errors.getApiErrorMessage(error) || this.translate.instant('common.errors.general'),
                        });
                      }
                    )
                  )
                )
              )
            : of() // When not logged in, the observable completes without emitting
      )
    )
  );

  private openReleasenotesDialog(releasenotes: Releasenote[]) {
    const ref = this.dialog.open(ReleasenotesDialogComponent, {
      width: '700px',
      disableClose: true,
      data: releasenotes,
    });

    ref.afterClosed().subscribe(() => {
      localStorage.setItem('last_seen', JSON.stringify(moment().format()));
      this.patchState({
        ...this.state,
        dialogopened: false,
      });
    });
  }
}

import { HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URITemplate } from '@app/api/services/uri-template';
import type { Money } from '@givve/ui-kit/models';
import { BaseHttpService } from '@givve/ui-kit/services';
import { Observable } from 'rxjs';

export const notificationTypes = [
  'voucher_configuration_update',
  'voucher_transaction_successful',
  'voucher_transaction_declined',
  'voucher_loaded',
  'pending_3ds_transaction',
];
export type NotificationType = (typeof notificationTypes)[number];

export interface PushNotificationPayload {
  type: NotificationType | null;
  voucher_transaction_id: string | null;
  voucher_id: string | null;
  transaction_id: string | null;
  transaction_amount: Money | null;
  transaction_time: string | null;
  merchant_name: string | null;
}

@Injectable({ providedIn: 'root' })
export class PushNotificationHttpService extends BaseHttpService {
  baseUri = new URITemplate('{+api_v1_base}/notifications');

  pushNotification(data: PushNotificationPayload): Observable<HttpEvent<any>> {
    const uri = this.baseUri.build();
    return this.post(uri, data);
  }
}

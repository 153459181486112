// @ts-strict-ignore
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthFacade } from '@app/auth';
import { NotificationService } from '@givve/ui-kit/services';
import { Errors } from '@givve/ui-kit/utils';
import { TranslateService } from '@ngx-translate/core';
import { Observable, catchError, throwError } from 'rxjs';

@Injectable()
export class ErrorInterceptorService implements HttpInterceptor {
  private f = inject(AuthFacade);
  private translate = inject(TranslateService);
  private notification = inject(NotificationService);
  protected dialog = inject(MatDialog);
  private router = inject(Router);

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error) => {
        // skip error handling for authorization endpoints
        if (request.url.includes('/api/admin/sessions')) {
          return;
        }
        if (error instanceof HttpErrorResponse) {
          switch (error.status) {
            case 401:
              this.f.forceLogout(this.translate.instant('common.session_expired'));
              this.dialog.closeAll();
              break;
            case 403: {
              this.router.navigateByUrl('/');
              this.notification.clear();
              this.notification.open({
                message: Errors.getApiErrorMessage(error) + this.translate.instant('common.access_forbidden'),
              });
              this.notification.reset();
              break;
            }

            default:
              break;
          }
        }

        return throwError(() => error);
      })
    );
  }
}

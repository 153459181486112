import { inject } from '@angular/core';
import { AuthFacade } from '../store/auth.facade';
import { TokenService } from './token.service';

export const authGuard = () => {
  const tokenService = inject(TokenService);
  const authFacade = inject(AuthFacade);

  if (tokenService.isTokenValid()) {
    return true;
  }

  authFacade.forceLogout();

  return false;
};

export const uiKitVoucherOwnerIcon = {
  data: `<svg width="24" height="22" viewBox="8 8 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M21 22.2L29.9975 22.2C30.108 22.2 30.1975 22.1105 30.1975 22L30.1979 20.2497L30.1986 16.4997L30.1993 12.7497L30.1996 11C30.1996 10.8896 30.1101 10.8 29.9996 10.8L27.25 10.8L22.5 10.8L15 10.8C14.8895 10.8 14.8 10.8895 14.8 11L14.8 14H13V11C13 9.89543 13.8954 9 15 9H22.5H27.25H29.9996C31.1043 9 31.9998 9.89566 31.9996 11.0004L31.9993 12.75L31.9986 16.5L31.9979 20.25L31.9975 22.0004C31.9973 23.1048 31.1019 24 29.9975 24L21 24V22.2Z" fill="black"/>
<path d="M25 18.5C25 19.3284 24.3284 20 23.5 20C22.6716 20 22 19.3284 22 18.5C22 17.6716 22.6716 17 23.5 17C24.3284 17 25 17.6716 25 18.5Z" fill="black"/>
<path d="M28 18.5C28 19.3284 27.3284 20 26.5 20C25.6716 20 25 19.3284 25 18.5C25 17.6716 25.6716 17 26.5 17C27.3284 17 28 17.6716 28 18.5Z" fill="black"/>
<path d="M21 31.38V30.75C21 28.5409 19.2091 26.75 17 26.75H12C9.79086 26.75 8 28.5409 8 30.75V31.38" stroke="black" stroke-width="1.9" stroke-linecap="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.5004 17.7C13.0368 17.7 11.8504 18.8864 11.8504 20.35C11.8504 21.8136 13.0368 23 14.5004 23C15.9639 23 17.1504 21.8136 17.1504 20.35C17.1504 18.8864 15.9639 17.7 14.5004 17.7ZM10.1504 20.35C10.1504 17.9476 12.098 16 14.5004 16C16.9028 16 18.8504 17.9476 18.8504 20.35C18.8504 22.7524 16.9028 24.7 14.5004 24.7C12.098 24.7 10.1504 22.7524 10.1504 20.35Z" fill="black"/>
</svg>
`,
  name: 'voucher-owner' as const,
};

export const uiKitUnlimitedIcon = {
  data: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0.82 26 26">
<path d="M0 4.81641C0 2.60727 1.79086 0.816406 4 0.816406H22C24.2091 0.816406 26 2.60727 26 4.81641V22.8164C26 25.0255 24.2091 26.8164 22 26.8164H4C1.79086 26.8164 0 25.0255 0 22.8164V4.81641Z" fill="#717DA9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M19.878 6.25135C20.5281 6.36952 21 6.92884 21 7.58114V21.9333C21 22.68 20.3866 23.2854 19.6298 23.2854H7.37018C6.61347 23.2854 6 22.68 6 21.9333V5.35253C6 4.50766 6.77645 3.86971 7.6184 4.02277L19.878 6.25135ZM19.7019 7.58114C19.7019 7.54676 19.6771 7.51737 19.6428 7.51111L7.38323 5.28254C7.33895 5.27449 7.29806 5.30806 7.29806 5.35253V21.9333C7.29806 21.9725 7.33037 22.0044 7.37018 22.0044H19.6298C19.6697 22.0044 19.7019 21.9725 19.7019 21.9333V7.58114Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.0864 20.0119L11.9133 20.0115V21.7194H15.0864V20.0119ZM16.3845 19.4426C16.3845 19.0496 16.0617 18.731 15.6634 18.731L11.3365 18.7305C10.9382 18.7304 10.6152 19.0491 10.6152 19.4421V22.2887C10.6152 22.6818 10.9381 23.0004 11.3364 23.0004H15.6633C16.0616 23.0004 16.3845 22.6818 16.3845 22.2887V19.4426Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.17285 12.3262H12.7786V13.7495H9.17285V12.3262Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.2212 12.3262H17.827V13.7495H14.2212V12.3262Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.17285 10.1914H12.7786V11.6147H9.17285V10.1914Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.17285 14.4609H12.7786V15.8842H9.17285V14.4609Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.2212 10.1914H17.827V11.6147H14.2212V10.1914Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.2212 14.4609H17.827V15.8842H14.2212V14.4609Z" fill="white"/>
</svg>`,
  name: 'unlimited' as const,
};

import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { URITemplate } from '@app/api/services/uri-template';
import type { ApiResponse } from '@givve/ui-kit/models';
import { Observable, map } from 'rxjs';
import { LunchTaxRules } from '../models/lunch-tax-rules';

@Injectable({
  providedIn: 'root',
})
export class LunchTaxRulesService {
  private httpClient = inject(HttpClient);

  private lunchCardTaxRuleUri = new URITemplate('{+api_v1_base}/lunch_card/tax_rule_test');

  calculateLunchTax(data: LunchTaxRules): Observable<LunchTaxRules> {
    return this.httpClient
      .post<ApiResponse<LunchTaxRules>>(this.lunchCardTaxRuleUri.build(), data)
      .pipe(map((response) => response.data));
  }
}

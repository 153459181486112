import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { VoucherHttpService } from '@app/api/services/voucher-http.service';
import {
  PushNotificationHttpService,
  PushNotificationPayload,
  notificationTypes,
} from '@app/features/testing/push-notification/services/push-notification-http.service';
import { removeNullAndEmptyFromObject, valueToMoney } from '@app/shared';
import { ButtonSpinnerComponent, ContentToolbarComponent } from '@givve/ui-kit/components';
import { TrimDirective } from '@givve/ui-kit/directives';
import type { RequestOptions } from '@givve/ui-kit/models';
import { NotificationService } from '@givve/ui-kit/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgxCurrencyDirective } from 'ngx-currency';
import { BehaviorSubject, switchMap } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-push-notification',
  standalone: true,
  imports: [
    CommonModule,
    ContentToolbarComponent,
    TranslateModule,
    MatCardModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatInputModule,
    ButtonSpinnerComponent,
    MatButtonModule,
    NgxCurrencyDirective,
    TrimDirective,
  ],
  templateUrl: './push-notification.component.html',
})
export class PushNotificationComponent {
  private pushNotificationHttpService = inject(PushNotificationHttpService);
  private voucherHttpService = inject(VoucherHttpService);
  private fb = inject(FormBuilder);
  private notification = inject(NotificationService);
  private translate = inject(TranslateService);

  types = notificationTypes;
  isProcessing$ = new BehaviorSubject<boolean>(false);

  form = this.fb.group({
    type: <string | null>null,
    voucher_token: <string | null>null,
    voucher_transaction_id: <string | null>null,
    transaction_id: <string | null>null,
    transaction_amount: <string | null>null,
    transaction_time: <string | null>null,
    merchant_name: <string | null>null,
  });

  onTypeSelectionChanged() {
    this.form.reset({
      type: this.form.get('type')?.value,
    });
  }

  onSubmit() {
    this.isProcessing$.next(true);
    const formValue = this.form.getRawValue();
    const payload: PushNotificationPayload & { voucher_token: string | null } = {
      ...formValue,
      voucher_token: null,
      voucher_id: null,
      transaction_amount: valueToMoney(formValue.transaction_amount),
    };

    this.pushNotification(payload);
  }

  private pushNotification(payload: PushNotificationPayload) {
    const filterTokenOption: RequestOptions = { filter: { token: { $in: this.form.get('voucher_token')?.value } } };

    this.voucherHttpService
      .getObjects({}, filterTokenOption)
      .pipe(
        untilDestroyed(this),
        switchMap((response) =>
          this.pushNotificationHttpService.pushNotification({
            ...removeNullAndEmptyFromObject(payload),
            voucher_id: response.data[0]?.id,
          } as PushNotificationPayload)
        )
      )
      .subscribe({
        next: () => {
          this.isProcessing$.next(false);
          this.notification.open({
            message: this.translate.instant('common.sent_success'),
            config: { duration: 2000 },
          });
        },
        error: (e) => {
          this.isProcessing$.next(false);
          this.notification.open({
            message: `${e.status}: ${JSON.stringify(e.error)}`,
          });
        },
      });
  }
}
